//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	data(){
		return {
			dataarr:[
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				},
				{
					title1:'2022-01-19',
					title2:'鲁B·F85F9',
					title3:'14235公里',
					title4:'维修内容维修内容维修内容维修内容',
				}
			],
			currentPage: 1,
		}
	},
	methods:{
		edit(){
			this.$router.push('record-detaile')
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},
	}
}
